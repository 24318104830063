import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Main from './pages/Main';
import CV from './pages/CV';

const firebaseConfig = {

  apiKey: "AIzaSyBwhfE5UQ34yezEbJINMc6aBSEET8IZD9E",

  authDomain: "vini-portfolio-test.firebaseapp.com",

  projectId: "vini-portfolio-test",

  storageBucket: "vini-portfolio-test.appspot.com",

  messagingSenderId: "753138193435",

  appId: "1:753138193435:web:2496a1bdb6bc59e0a354be",

  measurementId: "G-E55DS23JPQ"

};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Main/>}/>
        <Route path="/CV" element={<CV/>}/>
      </Routes>
    </Router>
  )
}

export default App;
